import React, { useState, useEffect } from "react";
import "../App.css";
import TimeRangeSlider from "../components/TimeRangeSlider";
import MapView from "../components/MapView";
import ApiCall from "../components/ApiCall";
import Colorbars from "../components/Colorbars";

function Home() {
  /**
   * statessa API:lta saatu data json muodossa weatherData muuttujassa
   * dataChoice muuttujassa tieto mitä dataa pyydetään API:lta
   * dateChoice muuttujassa tieto valitusta päivämäärästä string muodossa
   */
  const [weatherData, setWeatherData] = useState(0);
  const [dataChoice, setDataChoice] = useState("avgTemp");
  const [dateChoice, setDateChoice] = useState(["31-12-1950", "31-12-2023"]);

  useEffect(() => {
    console.log(`Datachoice value: ${dataChoice}`);
    console.log("[API]: Date, " + dateChoice);
    ApiCall(dataChoice, dateChoice)
      .then((res) => {
        if (res.ok) {
          // palautetaan json vain jos status 200
          console.log(`[API]: HTTP request OK, status: ${res.status}`);
          return res.json();
        } // muutoin heitetään uusi error
        throw new Error(`API returned error: ${res.statusText}`);
      })
      .then((data) => {
        setWeatherData(data);
        console.log("[API]: Request succesful, data saved in state variable");
      })
      .catch((error) =>
        console.error(`[API]: Error occured while fetching data: ${error}`)
      );
  }, [dataChoice, dateChoice]); // re-fetch data when dataChoice or dateChoice variable's value changes

  const options = [
    {
      value: "avgTemp",
      label: "Average Temperature",
      src: "temperatures.png",
    },
    {
      value: "avgTempComparison",
      label: "Compare average temperatures",
      src: "comparison.png",
    },
    {
      value: "precipitation",
      label: "Precipitation",
      src: "raining.png",
    },
    {
      value: "minTemp",
      label: "Minimum Temperature",
      src: "min.png",
    },
    {
      value: "maxTemp",
      label: "Maximum Temperature",
      src: "max.png",
    },
    {
      value: "radiation",
      label: "UV Radiation",
      src: "radiation.png",
    },
  ];

  return (
    <div className="App">
      <div className="mapViewContainer">
        <MapView data={weatherData} dataChoice={dataChoice} date={dateChoice} />
      </div>
      <div className="colorbarSliderContainer" id="colorbarSliderContainer">
        <div className="options">
          {options.map((item) => (
            <div className="radioContainer" key={item.value}>
              <label className="radio-label" htmlFor={item.value}>
                <img
                  src={item.src}
                  alt={item.label}
                  className="radio-icons"
                ></img>
                <input
                  type="radio"
                  name="parameters"
                  value={item.value}
                  id={item.value}
                  className="radio-inputs"
                  checked={dataChoice === item.value}
                  onChange={(e) => setDataChoice(e.target.value)}
                />
                {item.label}
              </label>
            </div>
          ))}
        </div>
        <div>
          <TimeRangeSlider setDateChoice={setDateChoice} dataChoice={dataChoice}></TimeRangeSlider>
          <Colorbars x={dataChoice}></Colorbars>
        </div>
      </div>
    </div>
  );
}

export default Home;
