const API_BASE_URL = "/api/historicaldata";

/**
 * Gets data from the API.
 * @param {string} dataType - The type of requested data
 * @param {string[]} recordDates - Dates for requested data (DD-MM-YYYY) in an array
 * @param {string[]} coordinates - Array containing coordinates in string format for a specific location
 * @returns {Promise<Response>} Response object
 */
const ApiCall = (dataType, recordDates, coordinates) => {
    const allowedTypes = [
        "avgTemp",
        "precipitation",
        "minTemp",
        "maxTemp",
        "radiation",
        "avgTempComparison"
    ];

    if (allowedTypes.includes(dataType)) {
        // if parameter value is valid
        console.log(`[API]: data argument's value was valid: ${dataType}`);
        console.log(`[API]: Coordinates value: ${coordinates}`);
        let params;
        
        if (coordinates === undefined) { // if coordinates weren't provided
            params = new URLSearchParams({
                data: dataType,
                date: recordDates,
            });
        } else {
            params = new URLSearchParams({
                data: dataType,
                date: recordDates,
                coords: coordinates
            });
        }
        return fetch(`${API_BASE_URL}?${params}`);
    }

    // if problems with argument, reject promise
    console.error( `[API]: Argument dataType's value was invalid: '${dataType}'. Check the allowedTypes array and update it if needed.`);
    return Promise.reject(new Error("Argument value invalid"));
};

export default ApiCall;