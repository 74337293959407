import ReactSlider from "react-slider";
import React, { useState } from "react";
import { addEquivalentProjections } from "ol/proj";
import "./TimeRangeSlider.css";
/* import Slider from "./Slider"; */

function TimeRangeSlider({ setDateChoice, dataChoice }) {
  const [yearValue, setYearValue] = useState([1950, 2024]);
  const [valueMonth, setValueMonth] = useState([1]);
  const [valueDay, setValueDay] = useState([1]);
  let maxDay = daysInMonth(valueMonth, yearValue[0]);
  maxDay = daysInMonth(valueMonth, yearValue[0]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  /**
   * Creates a date string from the given values. Because of how React's rendering works,
   * the slider that calls this function passes the changed local value instead of using the
   * state value. This is because the API call would be sent before re-rendering the component and
   * if the component hasn't been re-rendered, values in state haven't been fully updated.
   * @param {number} chosenDay - Selected day (state or local value)
   * @param {number} chosenMonth - Selected month (state or local value)
   * @param {number[]} chosenYear - Selected years (state or local value)
   * @returns {string} Date as a string. Format: DD-MM-YYYY
   */
  const convertToDateString = (chosenDay, chosenMonth, chosenYear) => {
    return [
      chosenDay.toString() +
        "-" +
        chosenMonth.toString() +
        "-" +
        chosenYear[0].toString(),
      chosenDay.toString() +
        "-" +
        chosenMonth.toString() +
        "-" +
        chosenYear[1].toString(),
    ];
  };



  if (dataChoice === 'avgTempComparison') {
    return (
      <>
        <div className="sliders-container sliders-position">
          {/* <p className="sliderLabel">
          Choose the years for comparison: {yearValue[0]} and {yearValue[1]}
        </p> */}
  
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            defaultValue={yearValue}
            max={2024}
            min={1950}
            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
            onAfterChange={(yearValue) => {
              setYearValue(yearValue);
              console.log(
                "[TimeRangeSlider]: " +
                  valueDay.toString() +
                  "-" +
                  valueMonth.toString() +
                  "-" +
                  yearValue[1].toString()
              );
              setDateChoice(convertToDateString(valueDay, valueMonth, yearValue));
            }}
          />
  
          {/* <p className="sliderLabel">{months[valueMonth - 1]}</p> */}
  
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            defaultValue={[1]}
            max={12}
            min={1}
            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
            onAfterChange={(valueMonth) => {
              setValueMonth(valueMonth);
              setDateChoice(convertToDateString(valueDay, valueMonth, yearValue));
            }}
          />
        </div>
      </>
    );
  }
    
  return (
    <>
      <div className="sliders-container sliders-position">
        {/* <p className="sliderLabel">
        Choose the years for comparison: {yearValue[0]} and {yearValue[1]}
      </p> */}

        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={yearValue}
          max={2024}
          min={1950}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          onAfterChange={(yearValue) => {
            setYearValue(yearValue);
            console.log(
              "[TimeRangeSlider]: " +
                valueDay.toString() +
                "-" +
                valueMonth.toString() +
                "-" +
                yearValue[1].toString()
            );
            setDateChoice(convertToDateString(valueDay, valueMonth, yearValue));
          }}
        />

        {/* <p className="sliderLabel">{months[valueMonth - 1]}</p> */}

        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[1]}
          max={12}
          min={1}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          onAfterChange={(valueMonth) => {
            setValueMonth(valueMonth);
            setDateChoice(convertToDateString(valueDay, valueMonth, yearValue));
          }}
        />

        {/* <p className="sliderLabel">
        Choose the day: {valueDay} and max day: {maxDay}
      </p> */}
      
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[1]}
          max={maxDay}
          min={1}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          onAfterChange={(valueDay) => {
            setValueDay(valueDay);
            setDateChoice(convertToDateString(valueDay, valueMonth, yearValue));
          }}
        />
      </div>
    </>
  );
}



function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export default TimeRangeSlider;
