import { useEffect, useState, useRef } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector.js";
import Overlay from "ol/Overlay.js";
import VectorSource from "ol/source/Vector.js";
import GeoJSON from "ol/format/GeoJSON";
import OSM from "ol/source/OSM";
import { fromLonLat, toLonLat } from "ol/proj";
import { defaults as defaultInteractions } from "ol/interaction";
import {Attribution, defaults as defaultControls} from 'ol/control.js';
import "./MapView.css";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import ApiCall from "./ApiCall";

//const europe_center = fromLonLat([12.126256, 55.5775]);
const atlantic = fromLonLat([-45.38126863573399, 51.25984802720819]); //coordinates to have Europe on the right side of the map on page load
const API_KEY = process.env.REACT_APP_OWEATHER_KEY; //API KEY for OpenWeather

function MapView({ data, dataChoice, date }) {
  const [coordinateChoice, setCoordinateChoice] = useState([]);
  const [locationData, setLocationData] = useState({}); // has data received from the API for the clicked location
  const [map, setMap] = useState();
  const [overlay, setOverlay] = useState();
  const [zoomLevel, setZoomLevel] = useState(3.15); //state for zoom-level to keep it between dataset switches
  const [mapCenter, setMapCenter] = useState(atlantic); //state to keep map at place between dataset switches

  const container = document.getElementById("popup-container"); //container-div to hold coordinate info elements

  const mapRef = useRef(); //ref to use map smoothly
  mapRef.current = map;

  const overlayRef = useRef(); //ref to use overlay smoothly
  overlayRef.current = overlay;

  const contentRef = useRef(null); //ref to handle coordinate info elements smoothly
  const closerRef = useRef(null); //ref to handle coordinate info closer smoothly

  //attribution for OpenLayers and OpenStreetMaps
  const attribution = new Attribution({
    collapsible: false,
    //attributions: `<a href="https://openlayers.org">Map made with Openlayers using</a><br>
    //               <a href="https://www.openstreetmap.org/">OpenStreetMap</a>`,
  });

  //create VectorLayer which will have country borders created from GeoJSON
  const borderLayer = new VectorLayer({
    source: new VectorSource({
      url: "./europe.geojson",
      format: new GeoJSON(),
    }),
    style: new Style({
      stroke: new Stroke({
        color: "#000000",
      }),
    }),
  });

  borderLayer.setOpacity(0.4);
  //borderLayer.setZIndex(1);

  console.log(`[MapView]: Koordinaatit: ${coordinateChoice}`);
  //console.log(`Koordinaateille saatu data: ${JSON.stringify(locationData)}`);

  useEffect(() => {
    //create VectorSource from GeoJSON acquired from backend
    const weatherMap = new VectorSource({
      features: new GeoJSON().readFeatures(data, {
        featureProjection: "EPSG:3857",
      }),
    });

    //style the VectorSource with data got from GeoJSON
    weatherMap.getFeatures().map((feature) => {
      feature.setStyle(
        new Style({
          fill: new Fill({
            color: feature.values_.stroke,
          }),
          stroke: new Stroke({
            color: feature.values_.stroke,
            //color: "#000000",
          }),
        })
      );
      return 0;
    });

    //create VectorLayer to draw the weathermap
    const weatherLayer = new VectorLayer({
      source: weatherMap,
    });

    //set opacity to 0.8 to see place names from under the weathermap layer
    weatherLayer.setOpacity(0.8);

    //create Overlay to handle the coordinate info popups
    const overlay = new Overlay({
      element: container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    setOverlay(overlay);

    /**
     * create new map with OpenStreetMaps and attach previously created VectorLayers and overlay
     * set centering and zoomlevel based on state value
     */
    const map = new Map({
      interactions: defaultInteractions({
        mouseWheelZoom: true,
        doubleClickZoom: false,
        dragPan: true,
      }).extend([]),
      target: "map",
      controls: defaultControls({attribution: false}).extend([attribution]),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        weatherLayer,
        borderLayer,
      ],
      overlays: [overlay],
      view: new View({
        center: mapCenter,
        zoom: zoomLevel,
      }),
    });

    map.on("click", handleClick); //attach click handler for when you click a map to get coordinate info

    map.getView().on("change:resolution", handleZoom); //attach zooming handler to handle the zoom state change
    map.on("moveend", handleMove); //attach moving handler to handle map panning state change

    setMap(map);

    return () => map.setTarget(null);
  }, [data]);

  /**
   * Click handler to handle coordinate info acquirement and popup creation
   * First, clicked coordinates are transformed to degrees to use them nicely on backend
   * Then API call is made with choice of data, date and coordinates
   * After the API call has resolved, another API call is made to OpenWeather API to get location name and country for presentation purposes
   * Then all gathered data is put inside p-elements to be appended to container-div
   * @param {*} evt clicked point on map, in meters (Web Mercator)
   */
  const handleClick = (evt) => {
    console.log("Click event!");
    let lonlat = evt.coordinate; //coordinates from click event, in meters
    let localData; //local variable to store acquired data to be used inside the API call for popup creation
    let tempChart = "";
    lonlat = toLonLat(lonlat, "EPSG:3857"); //turn Web Mercator coordinates into degrees for backend usage
    setCoordinateChoice(lonlat);
    //console.log(`Coordinates after click: ${coordinateChoice} (should be empty)`);
    ApiCall(dataChoice, date, lonlat) // need to call the function with the local lonlat value, because state hansn't updated yet
      .then((res) => {
        if (res.ok) {
          // palautetaan json vain jos status 200
          console.log(
            `[MapView APICALL]: HTTP request OK, status: ${res.status}`
          );
          if(dataChoice === "avgTempComparison") {
            return res.text();
          }
          else {
            return res.json();
          }
        } // muutoin heitetään uusi error
        throw new Error(`API returned error: ${res.statusText}`);
      })
      .then((data) => {
        if(dataChoice === "avgTempComparison") {
          tempChart = data;
          console.log(tempChart.length);
          console.log("lole");
        }
        setLocationData(data); // Save received data to state variable
        localData = data; // Save data to a local variable as well (backup)
        let content = document.createElement("p"); //p-element to save place information
        let text; //variable where text for above element will be added
        let codeContainer = document.createElement("p"); //p-element to have location data inside code-block for nicer look
        let code = document.createElement("code");
        codeContainer.appendChild(code); 
        let codeText; //variable where text for code block above is saved
        let imageContainer = document.createElement("img");
        /**
         * handleCoordinateInfo does API call to OpenWeather
         * The acquired data has place and country names that will be used for presentation purposes
         * dataChoice is used to determine what kind of text will be added inside the popup
         */
        handleCoordinateInfo(lonlat).then((data) => {
          if (localData !== -999) {
            let place = data[0].name;
            let country = data[0].country;
            switch (dataChoice) {
              case "avgTemp":
              case "maxTemp":
              case "minTemp":
                text = document.createTextNode("Temperature at " + place + ", " + country + " is: ");
                codeText = document.createTextNode(localData + " °C");
                break;
              case "precipitation":
                text = document.createTextNode("Amount of rain at " + place + ", " + country + " is: ");
                codeText = document.createTextNode(localData + " mm");
                break;
              case "radiation":
                text = document.createTextNode("Amount of radiation at " + place + ", " + country + " is: ");
                codeText = document.createTextNode(localData + " W/m^2");
                break;
              case "avgTempComparison":
                text = document.createTextNode(place + ", " + country);
                break;
              default:
                console.log("You clicked something you weren't supposed to");
            }
            //if popup window has content in it, clear it
            if(contentRef.current.hasChildNodes()){
              contentRef.current.removeChild(contentRef.current.firstElementChild);
            }
            if(dataChoice === "avgTempComparison") {
              content.appendChild(text);
              imageContainer.src = "data:image/png;base64,"+ tempChart;
              content.appendChild(imageContainer);
              contentRef.current.appendChild(content);
            }
            else {
              //append p-elements inside content-div
              content.appendChild(text);
              code.appendChild(codeText);
              content.appendChild(codeContainer);
              //append content-div to container-div
              contentRef.current.appendChild(content);
            }
            //set the position of popup window on top of clicked point
            overlayRef.current.setPosition(evt.coordinate);
          }
          //add functionality to the popup closer element
          closerRef.current.onclick = function () {
            overlayRef.current.setPosition(undefined);
            closerRef.current.blur();
          };
        });
        console.log(
          "[MapView APICALL]: Request succesful, data saved in state variable"
        );
        //console.log(`localdata: ${localData}`);
      })
      .catch((error) =>
        console.error(
          `[MapView APICALL]: Error occured while fetching data: ${error}`
        )
      );
  };

  //move handler gets extent of current viewport and uses that to calculate coordinates to center the map
  const handleMove = () => {
    let extent = mapRef.current
      .getView()
      .calculateExtent(mapRef.current.getSize());
    calculateCoordinates(extent);
  };

  //zoom handler sets current zoom level to state
  //gets extent of current viewport and uses that to calculate coordinates to center of the map
  const handleZoom = () => {
    setZoomLevel(mapRef.current.getView().getZoom());
    let extent = mapRef.current
      .getView()
      .calculateExtent(mapRef.current.getSize());
    calculateCoordinates(extent);
  };

  /**
   * Function to calculate center of viewport with given extent
   * Handles only exceptions inside Europes scope
   * @param {*} extent extent of a viewport, in array of [x_left, y_top, x_right, y_bottom]
   */
  function calculateCoordinates(extent) {
    let x;
    let y;
    //if x_left is on left side of Meridian
    if (extent[0] < 0 && extent[2] > 0) {
      x = extent[0] + (Math.abs(extent[0]) + Math.abs(extent[2])) / 2;
    //if both x_left and x_right are left side of Meridian
    } else if (extent[0] < 0 && extent[2] < 0) {
      x = (extent[0] + extent[2]) / 2;
    } else {
      x = (Math.abs(extent[0]) + Math.abs(extent[2])) / 2;
    }
    y = (Math.abs(extent[1]) + Math.abs(extent[3])) / 2;
    setMapCenter([x, y]);
  }

  //API call for OpenWeather to get location info
  const handleCoordinateInfo = async (coordinates) => {
    return fetch(
      "http://api.openweathermap.org/geo/1.0/reverse?lat=" +
        coordinates[1] +
        "&lon=" +
        coordinates[0] +
        "&limit=1&appid=" +
        API_KEY
    )
      .then((response) => {
        if (!response.ok) {
          console.error("Ei toiminut sijaintitiedon haku");
        }
        return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="mapViewDiv">
      <div ref={mapRef} id="map" style={{ width: "90vw", height: "85vh" }} />
      <div id="popup-container">
        <p ref={closerRef} id="popup-closer"></p>
        <div ref={contentRef} id="popup-content"></div>
      </div>
    </div>
  );
}

export default MapView;
