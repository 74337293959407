import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <section>
        <h1>Software description</h1>
        <p>
          <b>European weather history</b> is an application that allows you to
          view historical weather data from Europe. In addition, the application
          allows you to compare temperature differences between two different
          years.
        </p>
        <p>
          Sovellus jolla voi tarkastella historiallista säädataa euroopasta.
          Lisäksi, sovelluksella voi vertailla kahden eri vuoden lämpötilaeroja.
        </p>
      </section>
      <section>
        <h1>License</h1>
        <p>
          This work is licensed under <b>CC BY-NC-SA 4.0</b>. To view a copy of
          this license, visit{" "}
          <a
            href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
            title="License"
            target="_blank"
            rel="noopener noreferrer"
          >
            "https://creativecommons.org/licenses/by-nc-sa/4.0/"
          </a>
        </p>
      </section>
      <section>
        <h1>Dataset Acknowledgement</h1>
        <p>
          "We acknowledge the E-OBS dataset from the EU-FP6 project UERRA{" "}
          <a
            href="http://www.uerra.eu"
            alt="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            (http://www.uerra.eu)
          </a>
          and the Copernicus Climate Change Service, and the data providers in
          the ECA&D project
          <a
            href="https://www.ecad.eu"
            alt="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            (https://www.ecad.eu)
          </a>
          "Cornes, R., G. van der Schrier, E.J.M. van den Besselaar, and P.D.
          Jones. 2018: An Ensemble Version of the E-OBS Temperature and
          Precipitation Datasets, J. Geophys. Res. Atmos., 123.
          doi:10.1029/2017JD028200".
        </p>
        <p> Dataset version: 30.0e</p>
      </section>
      <section>
        <h1>Map implementation</h1>
        <p>
          Interactive map was created using <a href="https://openlayers.org/">OpenLayers</a>
        </p>
      </section>
      <section>
        <div>
          <h1>Flaticon Icons</h1>
          <ol>
            <li>
              <a
                href="https://www.flaticon.com/free-icons/clouds-and-sun"
                title="clouds and sun icons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Clouds and sun icons created by Freepik - Flaticon
              </a>
            </li>
            <li>
              <a
                href="https://www.flaticon.com/free-icons/uv-radiation"
                title="uv radiation icons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Uv radiation icons created by Metami septiana - Flaticon
              </a>
            </li>
            <li>
              <a
                href="https://www.flaticon.com/free-icons/hot"
                title="hot icons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hot icons created by Freepik - Flaticon
              </a>
            </li>
            <li>
              <a
                href="https://www.flaticon.com/free-icons/low-temperature"
                title="low temperature icons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Low temperature icons created by Freepik - Flaticon
              </a>
            </li>
            <li>
              <a
                href="https://www.flaticon.com/free-icons/temperature"
                title="temperature icons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Temperature icons created by Smashicons - Flaticon
              </a>
            </li>
            <li>
              <a
                href="https://www.flaticon.com/free-icons/rain"
                title="rain icons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rain icons created by Umeicon - Flaticon
              </a>
            </li>
            <li>
              <a
                href="https://www.flaticon.com/free-icons/temperature-changes"
                title="temperature comparison icons"
                target="_blank"
                rel="noopener noreferrer"
              >
                Temperature comparison icons created by Freepik - Flaticon
              </a>
            </li>
          </ol>
        </div>
      </section>
      <section>
        <h1>Location data</h1>
        <p>
          <a
            href="https://openweathermap.org/"
            title="openweathermap"
            target="_blank"
            rel="noopener noreferrer"
          >
            Location data provided by OpenWeather
          </a>
        </p>
        <img src="openweathermap.png" alt="openweathermap logo" width="10%" height="auto"></img>
      </section>
    </div>
  );
}

export default About;
