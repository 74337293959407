import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <p>
        Icons used in this project are from{" "}
        <a
          href="https://www.flaticon.com/"
          title="Flaticon"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.flaticon.com
        </a>
        . To read more visit
        <Link to="/About"> "About page"</Link>!
      </p>
    </footer>
  );
}

export default Footer;
