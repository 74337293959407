import React from "react";
import "./Colorbars.css";

const Colorbars = ({ x }) => {
  if (x === "precipitation") {
    return (
      <div className="colorbarContainer">
        <div className="cb-text">Precipitation, (mm):</div>
        <div className="cbimg-container">
          <img src="percipitationcb.png" alt=" " className="colorbar-img"></img>
        </div>
      </div>
    );
  }
  if (x === "avgTempComparison") {
    return (
      <div className="colorbarContainer">
        <div className="cb-text">Temperature, (°C):</div>
        <div className="cbimg-container">
          <img
            src="avgTempComparecb.png"
            alt=" "
            className="colorbar-img"
          ></img>
        </div>
      </div>
    );
  }
  if (x === "radiation") {
    return (
      <div className="colorbarContainer">
        <div className="cb-text">Radiation, (W/m2):</div>
        <div className="cbimg-container">
          <img src="radiationcb.png" alt=" " className="colorbar-img"></img>
        </div>
      </div>
    );
  } else {
    return (
      <div className="colorbarContainer">
        <div className="cb-text">Temperature, (°C):</div>
        <div className="cbimg-container">
          <img src="tempcb.png" alt=" " className="colorbar-img"></img>
        </div>
      </div>
    );
  }
};

export default Colorbars;
